

.labPanelItemContainer {
    border: 1px solid black;
    max-width: 375px;
    padding: 10px 0px 15px 0px;
    margin: auto;
}

.labPanelItemTitle {
    text-decoration: underline;
    font-weight: bold;
    margin: 5px 0px;
    margin-left: 10px;
}

.labPanelItemText {
    padding-left: 40px;
    margin: 3px 0px;
}

.labPanelIndentedItemText {
    padding-left: 80px;
}