

.simulationBodyContainer {
    padding: 0px 75px;
    width: calc(100% - 150px);
    /* overflow-y: auto; */
    padding-bottom: 20px;
    min-height: calc(100vh - 196px);
}

.simulationBodyHeaderWrapper {
    display: grid;
    grid-template-columns: auto 1fr;
}

.simulationBodyHeader {

}

.highlightedHeader {
    color: blue;
}

.simulationBodyImage {
    max-height: 300px;
    max-width: 100%;
    margin-top: 15px;
}

.simulationBodyText {
    font-size: 18px;
    white-space: pre-wrap;
}

.warningText {
    color: red;
}

.simulationBodyTable {
    border-collapse: collapse;
    border: 1px solid black;
    margin-left: 50px;
}

.simulationBodyTableRow {

}

.simulationBodyTableRow:nth-child(even) {
    background-color: #dddddd
}

.simulationBodyTableColumn {
    border: 1px solid black;
    padding: 2px 5px;
}

.simulationBodyPrevQuestionsContainer {
    border: 2px solid rgb(31, 31, 31);
    padding: 20px 10px;
    margin-left: 50px;
}

.simulationBodyPrevQuestionsTitle {
    margin: 0px;
    font-weight: bold;
    color: rgb(90, 90, 90);
}

.simulationBodyPrevQuestionsText {
    margin: 0px;
    color: rgb(90, 90, 90);
    white-space: pre-wrap;
}

.simulationBodyAnswersContainer {
    margin-left: 50px;
}

.simulationBodyAnswerWrapper {
    display: grid;
    grid-template-columns: 40px minmax(154px, 254px) 1fr;
    align-items: center;
    margin-bottom: 5px;
}

.simulationBodyAnswerNumber {
    margin: 0px;
}

.simulationBodyAnswerInput {
    height: 15px;
    max-width: 250px;
}

.simulationBodyRemainingCharactersText {
    margin: 0px;
    margin-left: 10px;
    font-weight: bold;
    font-size: 12px;
}

@media(max-width: 500px) {
    .simulationBodyContainer {
        padding: 0px 25px;
        padding-bottom: 40px;
        width: calc(100% - 50px);
        min-height: calc(100vh - 198px);
    }
}