

.simulationFooterWrapper {
    position: sticky;
    width: 100%;
    bottom: 0px;
}

.simulationFooterContainer {
    background-color: var(--logo-color);
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    /* column-gap: 300px; */
    align-items: center;
    justify-items: center;
    height: 50px;
}

.simulationFooterExitCaseButton {
    margin-right: 10px;
}

.simulationFooterButtonsWrapper {
    position: sticky;
    bottom: 0px;
    background-color: white;
    display: grid;
    padding: 10px 75px;
    grid-auto-flow: column;
}

.simulationFooterExitCaseButton,
.simulationFooterCommentButton,
.simulationFooterSubmitButton,
.simulationFooterOkButton {
    height: fit-content;
    width: fit-content;
    padding: 3px 8px;
}

@media(max-width: 700px) {
    .simulationFooterButtonsWrapper {
        display: none;
    }
}

@media(max-width: 600px) {
    .simulationFooterContainer {
        column-gap: 0px;
    }
}