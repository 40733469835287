input {
	cursor: pointer;
}

select {
	cursor: pointer;
}

label {
	cursor: pointer;
}

.app {
	width: 100%;
	height: 100%;
	user-select: none;
	display: -webkit-flexbox;
}

.case-list {
	display: grid;
	grid-template-rows: auto 1fr 39px auto;
	flex-direction: column;
	margin-left: 1vw;
	margin-right: 1vw;
	height: 100vh;
}

.page-header {
	display: grid;
	grid-auto-flow: column;
	justify-content: space-between;
	align-items: center;
	/* safari testing */
	margin: 0.25em 0;
}

.page-title {
	display: flex;
	font-size: 1.8em;
	font-weight: bold;
	align-items: center;
	margin-top: 1vh;
	margin-bottom: 1vh;
}

.page-error {
	margin: 0px 10px;
	color: red;
}

.table-row {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	width: 100%;
	cursor: pointer;
	min-height: 1.5em;
	align-items: center;
}

.wrapper {
	display: flex;
	flex-direction: row;
}

.column {
	flex-grow: 0;
	flex-shrink: 0;
	vertical-align: top;
}

:root {
	--id-width: 3em;
	--title-width: 15em;
	--category-width: 10em;
	--diagnosis-width: 20em;
	--time-width: 8em;
	--completed-width: 6em;
	--yield-width: 8em;
	--review-width: 6em;
	--grade-width: 8em;
}

.id { width: 3em }
.title { width: 15em }
.category { width: 10em }
.diagnosis { width: 20em }
.time { width: 8em; text-align: center; display: grid; align-items: center; }
.completed { width: 6em }
.yield { width: 8em }
.review { width: 6em }
.grade { width: 8em }

.title-category-diagnosis-time { 
	width: 15em + 10em + 20em + 8em;
	flex-grow: 1;
}

.completed-review-grade {
	/* width: 6em + 8em + 8em; */
	flex-grow: 0;
}

.completed-review-grade > .column {
	padding: 0px 10px;
}

.completed, .yield, .review, .grade {
	text-align: center;
}

/* growable columns */
.title, .category, .diagnosis {
	flex-grow: 1;
	/* white-space: nowrap; */
	text-overflow: ellipsis;
	align-self: center;
}

.table {
	/*border: 1px solid #F1F1F1;*/
	display: flex;
	flex-direction: column;
	border: 1px solid #ccc;
	overflow-y: auto;
}

.table-content {
	overflow-y: auto;
	width: 100%;
}

.table-row:nth-child(even) {
	background-color: var(--primary-color);
}

.table-content .table-row:hover {
	background-color: var(--secondary-color);
}

.header {
	background: linear-gradient(to bottom, #eee 0%, #ccc 100%);
}

/* safari */
.table-row.header {
	height: 4em;
}

.table-row.selected {
	display: flex;
	flex-direction: row;
	width: 100%;
	background-color: #0095c8 !important;
	color: #fff;
}

.options {
	display: grid;
	grid-auto-flow: column;
	justify-content: space-between;
	/* height: 10vh; */
	margin-bottom: 1em;
}

.simulation-menu-options {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin-bottom: 10px;
}

.table-options {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	height: 2vh;
	padding-bottom: 2vh;
}

.buttons {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 50px;
	margin-top: 10px;
}

.program-options {
	display: flex;
	flex-direction: row;
	justify-content: flex-end; 
}

.button {
	margin-left: 1vw;
}

.button.fade {
	opacity: 0.5;
	pointer-events: none;
}

.table-option-checkbox {
	margin-left: 1vw;
}

.spacer {
	width: 15px;
}

@media screen and (max-width: 650px) {
	.options {
		grid-auto-flow: row;
	}

	.program-options {
		display: initial;
	}
}

@media screen and (max-width: 800px) {
	:root {
		--id-width: 3em;
		--title-width: 10em;
		--category-width: 10em;
		--diagnosis-width: 10em;
		--time-width: 8em;
		--completed-width: 8em;
		--yield-width: 8em;
		--review-width: 8em;
		--grade-width: 8em;
	}

	.id { width: 3em }
	.title { width: 10em }
	.category { width: 10em }
	.diagnosis { width: 10em }
	.time { width: 8em; text-align: start; }
	.completed { width: 8em }
	.yield { width: 8em }
	.review { width: 8em }
	.grade { width: 8em }

	.wrapper {
		flex-direction: column;
	}

	.header {
		height: 70px !important;
	}

	.page-header { 
		grid-template-columns: auto auto auto;
		grid-template-rows: auto auto;
	}

	.page-error {
		grid-column-start: 1;
		grid-column-end: 4;
	}
	
	.title-category-diagnosis-time { 
		width: 10 + 10 + 10 + 8;
		flex-grow: 1;
	}

	.buttons {
		justify-content: space-evenly;
		margin-top: 10px;
	}

	.completed-review-grade {
		width: 8 + 8 + 8;
		flex-grow: 0;
	}

	.completed-review-grade > .column {
		padding: 0px;
	}

	.title, .category, .diagnosis {
		flex-grow: 1;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		align-self: auto;
	}
	
	.table-content {
		height: 64vh;
	}

	.spacer {
		width: 0;
	}
	.table-row {
		height: auto;
	}
}

@media screen and (max-width: 450px) {
	.header {
		height: 100px !important;
	}
}