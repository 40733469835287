

.highYieldPageContainer {
    position: absolute;
    display: flex;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 10000;
}

.highYieldBackdrop {
	position: absolute;
	left: 0;
	top: 0;
	height: 100vh;
	width: 100%;
	background-color: rgba(0, 0, 0, 0.55);
	z-index: 1;
}

@media(max-width: 440px) {
    .highYieldPageContainer {
        width: 122.5%;
    }
}