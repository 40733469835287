.answerPopupPageWrapper {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    z-index: 1;
    opacity: 1;
}

.hideAnswerPopup {
    opacity: 0;
    pointer-events: none;
}

.answerPopupBackdrop {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.5);
}

.answerPopupContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 600px;
    max-width: 750px;
    height: 100%;
    width: 95%;
    z-index: 2;
    background-color: white;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.4);
    display: grid;
    grid-template-rows: 40px calc(100% - 80px) 40px;
}

.answerPopupHeaderContainer {
    background-color: var(--logo-color);
    display: grid;
}

.answerPopupHeaderText {
    margin: 0px;
    color: white;
    align-self: center;
    margin-left: 10px;
    font-weight: bold;
}

.answerPopupBodyContainer {
    margin-left: 10px;
    margin-right: 10px;
    padding-top: 10px;
    max-width: 100%;
    overflow-x: hidden;
}

.answerPopupAnswerContainer {
    padding-top: 10px;
    overflow: hidden;
    overflow-y: auto;
    max-height: calc(100% - 10px);
}

.answerPopupCorrectAnswer, .answerPopupIncorrectAnswer {
    height: 8px;
    width: 8px;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    top: -1px;
    margin-right: 5px;
}

.answerPopupCorrectAnswer {
    background-color: green;
}

.answerPopupIncorrectAnswer {
    background-color: red;
}

.answerPopupAnswerText {
    margin: 0px;
    font-weight: bold;
    display: inline-block;
    white-space: pre-wrap;
}

.answerPopupExplanationText {
    margin: 0px;
}

.answerPopupFooterContainer {
    display: grid;
    align-items: center;
    justify-items: end;
    background-color: var(--logo-color);
}

.answerPopupFooterButton {
    margin-right: 10px;
}