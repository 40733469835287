
button {
  cursor: pointer;
}

.loadingPopupWrapper {
  position: fixed;
  height: 100vh;
  width: 100%;
  z-index: 1000;
  top: 0;
  left: 0;
}

.loadingPopupBackdrop {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.65);
  z-index: 15;
}

.loadingPopupContainer {
  position: absolute;
  height: 100px;
  padding: 25px 0px;
  padding-top: 35px;
  width: 250px;
  background-color: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 20;
  border-radius: 5px;
  display: grid;
  align-items: center;
  justify-items: center;
}