

.labPanelsPageWrapper {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    opacity: 1;
}

.hideLabPanelsPopup {
    opacity: 0;
    pointer-events: none;
}

.labPanelsBackdrop {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.5);
}

.labPanelsContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 600px;
    max-width: 740px;
    height: 100%;
    width: 95%;
    z-index: 2;
    background-color: white;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.4);
    display: grid;
}

.labPanelContentWrapper {
    overflow-y: auto;
    height: calc(100% - 10px);
    padding-bottom: 10px;
}

.labPanelsHeaderWrapper {
    background-color: #0000C0;
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
}

.labPanelsHeader {
    margin: 2px 0px;
    margin-left: 10px;
    color: white;
}

.labPanelsCloseButton {
    height: fit-content;
    width: fit-content;
    margin: 3px;
}

.labPanelsBodyWrapper {

}

.labPanelsTitleText {
    font-weight: bold;
    text-align: center;
}