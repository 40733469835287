

.selectedCheckboxesAmount {
    margin: 0px;
    margin-left: 30px;
}

.checkboxAnswersContainer {
    margin: 20px 0px;
    cursor: pointer;
}

.checkboxAnswerWrapper {
    display: grid;
    grid-template-columns: 30px auto;
    align-items: center;
}

.checkboxOptionText {
    margin: 0px;
}