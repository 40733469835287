

.exitCasePageContainer {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    z-index: 1;
    opacity: 1;
}

.hideExitCasePrompt {
    opacity: 0;
    pointer-events: none;
}

.exitCaseBackdrop {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.5);
}

.exitCaseContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 400px;
    max-width: 550px;
    height: 100%;
    width: 95%;
    z-index: 2;
    background-color: white;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.4);
    display: grid;
    grid-template-rows: 40px calc(100% - 80px) 40px;
}

.exitCaseHeaderContainer {
    background-color: var(--logo-color);
    display: grid;
}

.exitCaseHeaderText {
    margin: 0px;
    color: white;
    align-self: center;
    margin-left: 10px;
    font-weight: bold;
}

.exitCaseBodyContainer {
    margin-left: 10px;
    margin-top: 10px;
}

.exitCaseBodyText {
    margin: 0px;
}

.exitCaseFooterContainer {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    justify-items: end;
    background-color: var(--logo-color);
}

.exitCaseFooterButton {
    margin-right: 10px;
}