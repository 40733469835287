

.commentsPageWrapper {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    z-index: 1;
    opacity: 1;
}

.hideCommentsPopup {
    opacity: 0;
    pointer-events: none;
}

.commentsBackdrop {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.5);
}

.commentsContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 328px;
    max-width: 512px;
    height: 100%;
    width: 100%;
    background-color: rgb(236, 233, 216);
    z-index: 2;
    display: grid;
    grid-template-rows: 24px calc(100% - 64px) 40px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.4);
}

.commentsHeaderWrapper {
    background-color: #0000C0;
    width: 100%;
    display: inline-block;
}

.commentsHeaderText {
    margin: 2px 0px;
    margin-left: 10px;
    color: white;
    font-size: 14px;
}

.commentsTextArea {
    margin: 10px;
    margin-bottom: 0px;
    resize: none;
    border-radius: 0px;
    padding: 3px;
}

@media(max-width: 600px) {
    .commentsTextArea {
        font-size: 16px;
    }
}

.commentsFooterWrapper {
    display: grid;
    align-items: center;
    justify-items: end;
}

.commentsCloseButton {
    height: fit-content;
    width: fit-content;
    margin-right: 10px;
    padding: 5px 20px;
}