

.simulationHeaderContainer {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    align-items: center;
    background-color: var(--logo-color);
    padding: 15px 0px;
    position: sticky;
    transition-duration: 0ms;
    width: 100%;
    top: 0px;
}

.simulationHeaderLeftContentWrapper {
    justify-self: left;
    display: grid;
    margin-left: 10px;
}

.simulationHeaderCenterContent {
    justify-self: center;
}

.simulationHeaderRightContentWrapper {
    justify-self: right;
    display: grid;
    grid-auto-flow: row;
    margin-right: 10px;
}

.simulationHeaderText {
    margin: 0px;
    color: white;
    font-size: 16px;
}

.simulationHeaderLabPanelsButton {
    display: none;
}

@media(max-width: 700px) {
    .simulationHeaderContainer {
        grid-template-columns: auto 110px;
        grid-template-rows: 1fr 1fr 1fr;
        row-gap: 5px;
    }

    .simulationHeaderLeftContentWrapper, .simulationHeaderCenterContent, .simulationHeaderRightContentWrapper {
        grid-column: 1;
    }

    .simulationHeaderLabPanelsButton {
        display: initial;
        grid-column: 2;
        grid-row-start: 1;
        grid-row-end: 4;
        height: fit-content;
        width: fit-content;
        margin-right: 10px;
    }

    .simulationHeaderCenterContent {
        justify-self: left;
        margin-left: 10px;
    }

    .simulationHeaderRightContentWrapper {
        justify-self: left;
        margin-left: 10px;
        margin-right: 0px;
    }
}