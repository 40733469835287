

.incompleteCasePopupPageWrapper {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
}

.incompleteCasePopupBackdrop {
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    cursor: pointer;
}

.incompleteCasePopupContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 438px;
    width: 95%;
    max-width: 338px;
    display: grid;
    grid-template-rows: 40px 345px 55px;
    border: 6px solid #bbcad6;
    border-radius: 7px;
    z-index: 2;
    background-color: white;
}

.incompleteCasePopupHeaderContainer {
    background-color: #bbcad6;
    display: grid;
    position: relative;
}

.incompleteCasePopupHeaderText {
    font-size: 17px;
    margin: 0px;
    align-self: center;
    font-weight: bold;
    color: black;
    margin-left: 5px;
}

.incompleteCaseCloseButton {
    position: absolute;
    right: 0;
    top: 0;
    height: 30px;
    width: 30px;
    background-color: rgba(0, 0, 0, 0);
    border: 0px;
    top: 50%;
    transform: translate(0, -50%);
    cursor: pointer;
}

.incompleteCaseCloseButton:before, .incompleteCaseCloseButton:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    height: 25px;
    width: 2px;
    border-radius: 3px;
    background-color: rgb(48, 48, 48);
}

.incompleteCaseCloseButton:before {
    transform: translate(-50%, -50%) rotate(45deg);
}

.incompleteCaseCloseButton:after {
    transform: translate(-50%, -50%) rotate(-45deg);
}

.incompleteCasePopupBodyContainer {
    overflow-y: auto;
}

.incompleteCaseContainer {
    border-bottom: 1px solid #c8c8c8;
    padding: 10px;
    cursor: pointer;
    display: inline-block;
    width: calc(100% - 20px);
    transition-duration: 100ms;
}

.selectedIncompleteCaseContainer {
    background-color: #9ebac0;
}

.incompleteCaseDate {
    font-size: 18px;
    margin: 0px;
    font-weight: 500;
    margin-bottom: 3px;
}

.incompleteCaseTime {
    font-size: 16px;
    margin: 0px;
}

.incompleteCasePopupFooterContainer {
    background-color: #bbcad6;
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 0px 30px;
}

.incompleteCaseButton {
    height: fit-content;
    width: fit-content;
    align-self: center;
    justify-self: center;
    padding: 5px 10px !important;
    cursor: pointer;
}