

.simulationPageContainer {
    /* display: grid;
    grid-template-rows: 66px calc(100vh - 146px) 30px 50px; */
}

p, h3, td, div {
    user-select: none;
}

.simulationBodyExitCaseButton {
    justify-self: start;
    height: fit-content;
    width: fit-content;
}

.simulationBodyLabPanelsButton {
    justify-self: end;
    height: fit-content;
    width: fit-content;
}

@media(max-width: 700px) {
    .simulationPageContainer {
        grid-template-rows: 130px calc(100vh - 180px) 50px;
    }
}