

.helpPopupPageWrapper {
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
}

.helpPopupPageBackdrop {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.helpPopupBodyContainer {
    overflow-y: auto;
    padding: 0px 10px;
}

.helpPopupBodyText {
    overflow-y: auto;
}