body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
	--primary-font-size: 16px;
	--secondary-font-size: 14px;
  --primary-color: #FCFCFC;
  --accent-color: #e6e6e6;
  --button-background-color: white;
  --off-color: #c8c8c8;
	--secondary-color: #bbcad6;
	--hover-color: #9ebac0;
	--selected-button-color: #B7C3CC;
	--header-font-color: #0B335D;
  --primary-font-color: black;
  --border-color: black;
  --gradient: linear-gradient(to bottom, #eee 0%, #ccc 100%);
  --reverse-gradient: linear-gradient(to top, #eee 0%, #ccc 100%);
  --logo-color: #003D7A;
}